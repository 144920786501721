import styled, { css } from 'styled-components';
import { CTASmall } from '../Typography/Typography.styles';

interface AnnouncementBarStyledProps {
  $variant?: 'primary' | 'secondary';
}

export const AnnouncementBarPrimary = css`
  background-color: ${(props) => props.theme.colors.wrenoYellowPrimary};
  color: ${(props) => props.theme.colors.black};
`;

export const AnnouncementBarSecondary = css`
  background-color: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.wrenoYellowPrimary};
`;

export const AnnouncementBarLabel = styled.span`
  ${CTASmall};
`;

export const AnnouncementBarActionLabel = styled.a<AnnouncementBarStyledProps>`
  ${CTASmall};
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) =>
    props.$variant === 'primary'
      ? props.theme.colors.black
      : props.theme.colors.wrenoYellowPrimary};
`;

export const AnnouncementTextContainer = styled.span``;

export const AnnouncementBarStyled = styled.div<AnnouncementBarStyledProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 17px 61px;
  position: relative;
  box-sizing: border-box;
  height: ${({ theme }) => theme.variables.announcementBarHeightMobile}px;
  @media ${({ theme }) => theme.breakpoints.large} {
    height: ${({ theme }) => theme.variables.announcementBarHeight}px;
    padding: 0 61px;
  }
  ${(props) => props.$variant === 'primary' && AnnouncementBarPrimary};
  ${(props) => props.$variant === 'secondary' && AnnouncementBarSecondary};
`;

export const AnnounceCloseButton = styled.button`
  position: absolute;
  background-color: transparent;
  border: 0;
  width: 45px;
  height: 45px;
  cursor: pointer;
  top: 5px;
  right: 3px;
  bottom: 0;
  padding: 0;
  @media ${({ theme }) => theme.breakpoints.large} {
    top: 0;
    right: 0;
    bottom: 0;
  }
`;
