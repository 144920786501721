import styled from 'styled-components';
import BaseModal from '../Modal';

export const Modal = styled(BaseModal)`
  padding: 0;
  overflow: hidden;
  border: none;
`;

export const Body = styled.div`
  padding: 2rem;
`;

export const Header = styled.div`
  background: ${({ theme }) => theme.colors.gray100};
  // Ensure to add padding for close button
  padding: 18px 48px 18px 24px;
  display: flex;
  gap: 1rem;
  border-radius: 16px 16px 0 0;
  width: 100%;
  box-sizing: border-box;
`;
