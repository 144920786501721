import type { SVGAttributes } from 'react';

const CircleExclamationFilledIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="15" cy="15" r="15" fill="currentColor" />
    <path
      d="M14.2864 16.5444L11.1923 6.98087C11.0356 6.49646 11.3968 6 11.9059 6H18.0941C18.6032 6 18.9644 6.49646 18.8077 6.98087L15.7136 16.5444C15.4896 17.2366 14.5104 17.2366 14.2864 16.5444Z"
      stroke="white"
      strokeWidth="1.5"
    />
    <circle cx="15" cy="23.25" r="2.25" stroke="white" strokeWidth="1.5" />
  </svg>
);

export default CircleExclamationFilledIcon;
