import styled from 'styled-components';
import Button from '../../Button';

export const SideMenuButton = styled(Button)`
  border: none;
  background: none;
  min-width: 50px;
`;

export const LinkContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;

  & .context_menu_button_wrapper {
    padding: 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

export const SideBarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  @media ${({ theme }) => theme.breakpoints.large} {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  margin-right: 87px;

  & img {
    cursor: pointer;
    max-width: 100px;
    max-height: 27px;
  }

  @media ${({ theme }) => theme.breakpoints.large} {
    & img {
      max-width: 147px;
      max-height: 40px;
    }
  }
`;

export const InnerContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 20px;
  height: ${({ theme }) => theme.variables.headerHeightMobile}px;

  @media ${({ theme }) => theme.breakpoints.large} {
    padding: 0 50px;
    height: ${({ theme }) => theme.variables.headerHeight}px;
  }
`;

export const OuterContainer = styled.header<{
  $isSticky?: boolean;
  $sidebarOpen?: boolean;
  $backgroundColor: string;
  $announcementIsVisible: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  background: ${({ theme, $sidebarOpen, $backgroundColor }) =>
    $sidebarOpen ? theme.colors.black : $backgroundColor};
  box-sizing: border-box;
  position: relative;
  z-index: 100;

  ${({ $isSticky }) =>
    $isSticky &&
    `
    position: sticky;
    top: 0;
  `}
`;
