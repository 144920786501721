import React, { createContext, useContext, ReactNode } from 'react';

interface DarkModeContextType {
  darkMode: boolean;
}

const DarkModeContext = createContext<DarkModeContextType | undefined>(
  undefined,
);

interface DarkModeProviderProps {
  children: ReactNode;
  darkMode: boolean;
}

export const DarkModeProvider: React.FC<DarkModeProviderProps> = ({
  children,
  darkMode,
}) => {
  return (
    <DarkModeContext.Provider value={{ darkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = (): DarkModeContextType => {
  const context = useContext(DarkModeContext);
  if (context === undefined) {
    throw new Error('useDarkMode must be used within a DarkModeProvider');
  }
  return context;
};
