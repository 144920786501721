import styled from 'styled-components';
import Typography from '../Typography';

export const Title = styled(Typography)`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const Message = styled(Typography)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const MessageContentWrapper = styled.div``;

export const Container = styled.div<{ $hideBorder?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.machineGreen600};
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  width: 100%;

  ${({ $hideBorder }) => $hideBorder && `border: none;`}

  & svg {
    flex-shrink: 0;
  }
`;
