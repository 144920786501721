import styled from 'styled-components';
import Modal from '../../../Modal';
import getHeaderHeight from '../../../../utils/getHeaderHeight';

export const StyledModal = styled(Modal)`
  &.sidebar_modal {
    max-height: calc(100vh - ${getHeaderHeight(true)});
    max-width: 100vw;
    bottom: 0;
    top: ${(props) => props.theme.variables.headerHeightMobile}px;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
    background-color: ${(props) => props.theme.colors.black};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
