import { Announcement } from '../components/AnnouncementBar';
import { useCallback, useEffect, useMemo } from 'react';
import { useAnnouncementBarContext } from 'common/providers/AnnoucementBarProvider';
import { getItem, setItem } from 'common/utils/localStorage';

function announcementLocalStorageKey(announcement: Announcement) {
  return `AB${announcement.text}:${announcement.link?.url || ''}`;
}

const DISMISSED = 'dismissed';

export default function useAnnouncementBar(announcement?: Announcement | null) {
  const { showAnnouncementBar, setShowAnnouncementBar } =
    useAnnouncementBarContext();

  const key = useMemo(
    () => (announcement ? announcementLocalStorageKey(announcement) : null),
    [announcement],
  );

  useEffect(() => {
    if (key) {
      const value = getItem(key);
      if (value !== DISMISSED) {
        setShowAnnouncementBar(true);
      }
    }
  }, [key, setShowAnnouncementBar]);

  const onClose = useCallback(() => {
    if (key) {
      setItem(key, DISMISSED);
      setShowAnnouncementBar(false);
    }
  }, [key, setShowAnnouncementBar]);

  return {
    isVisible: showAnnouncementBar,
    onClose,
  };
}
