import Link from 'next/link';
import Image from 'next/image';
import LogoImage from '../../assets/wreno-logo-with-name-dark.png';
import useResponsive from '../../hooks/useResponsive';
import {
  FooterInnerContainer,
  FooterLargeLinkContainer,
  FooterLinkContainer,
  FooterOuterContainer,
  LogoWrapper,
  Title,
  TitleLink,
} from './Footer.styles';
import Typography from '../Typography';
import type { Link as LinkType } from '../website/PrimaryNav/types';

type Props = {
  links: LinkType[];
};

function FooterLinks({ links }: Props) {
  return (
    <>
      {links?.map((link) => (
        <TitleLink key={link.url} href={link.url || '/'}>
          <Title variant={'bodyStandard'} color={'gray400'}>
            {link.title}
          </Title>
        </TitleLink>
      ))}
    </>
  );
}

const now = new Date();

export default function Footer(props: Props) {
  const { isLarge } = useResponsive();

  function renderLinkContainer() {
    if (isLarge) {
      return (
        <FooterLargeLinkContainer>
          <FooterLinks links={props.links} />
        </FooterLargeLinkContainer>
      );
    }
    return (
      <FooterLinkContainer>
        <FooterLinks links={props.links} />
      </FooterLinkContainer>
    );
  }

  return (
    <FooterOuterContainer>
      <FooterInnerContainer>
        <LogoWrapper>
          <Link href="/">
            <Image
              data-testid={'footer-logo'}
              src={LogoImage}
              width={140}
              height={38}
              alt="Wreno Logo"
            />
          </Link>
        </LogoWrapper>
        {renderLinkContainer()}
        <Typography
          variant={'bodyStandard'}
          color={'gray400'}
          data-testid={'footer-copyright'}
        >
          © {now.getFullYear()} Wreno Inc. All rights reserved
        </Typography>
      </FooterInnerContainer>
    </FooterOuterContainer>
  );
}
