import styled from 'styled-components';
import Button from '../../Button';

export const Container = styled.div<{ $immersive?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  ${({ $immersive }) =>
    $immersive &&
    `
    padding: 0 16px;
  `}
  @media ${({ theme }) => theme.breakpoints.large} {
    padding: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BackButton = styled(Button)`
  margin-bottom: 8px;
`;
