import type { DefaultTheme } from 'styled-components';

export default function getHeaderHeight(mobile = false) {
  return ({
    theme,
    $announcementIsVisible,
  }: {
    theme: DefaultTheme;
    $announcementIsVisible?: boolean;
  }) => {
    let headerHeight: number = theme.variables.headerHeight;
    let announcementBarHeight: number = theme.variables.announcementBarHeight;
    if (mobile) {
      headerHeight = theme.variables.headerHeightMobile;
      announcementBarHeight = theme.variables.announcementBarHeightMobile;
    }
    if ($announcementIsVisible) {
      return headerHeight + announcementBarHeight;
    } else {
      return headerHeight;
    }
  };
}
