import { motion } from 'framer-motion';
import styled from 'styled-components';
import { BodyLarge, CTASmall, Heading5 } from '../Typography/Typography.styles';

export type VariantProp = 'primary' | 'secondary' | undefined;

export type WithVariant = { $variant: VariantProp };

export const Accordion = styled.div.withConfig({
  shouldForwardProp: () => true,
})<WithVariant>`
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray400};

  ${({ $variant }) =>
    $variant === 'secondary' &&
    `
    border-bottom: 0;
  
  `}
`;

export const AccordionHeader = styled.div<
  {
    $isOpen?: boolean;
    $shouldRotate?: boolean;
  } & WithVariant
>`
  width: 100%;
  padding: 20px 0 20px 0;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  justify-content: space-between;
  ${Heading5};
  color: ${({ theme }) => theme.colors.black};
  ${({ $variant }) =>
    $variant === 'secondary' &&
    `
    ${CTASmall};
    font-size: 14px;
    padding: 0;
    margin-bottom: 8px;
  `}
`;

export const AccordionContent = styled(motion.div)`
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black};
  ${BodyLarge};
`;

export const AccordionContentWrapper = styled.div<WithVariant>`
  padding: 0 20px 20px;
  ${({ $variant }) =>
    $variant === 'secondary' &&
    `
    padding: 0 0 20px;
  `}
`;
