import { useRouter } from 'next/router';
import { Link, DrawerLink } from './LinkSimple.styles';
import { useDarkMode } from '../../../../hooks/useDarkMode';

type LinkSimpleProps = {
  title: string;
  href: string;
  isDrawer?: boolean;
};

const LinkSimple = ({ title, href, isDrawer }: LinkSimpleProps) => {
  const { darkMode } = useDarkMode();
  const { asPath } = useRouter();
  const isActive = asPath === href;
  const LinkType = isDrawer ? DrawerLink : Link;

  return (
    <LinkType href={href} $isActive={isActive} $darkMode={darkMode}>
      {title}
    </LinkType>
  );
};

export default LinkSimple;
