import { SVGProps } from 'react';

const AccordionMinusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={34}
    height={34}
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={17} cy={17} r={17} fill="#131313" />
    <path d="M25 16.5v2H9v-2h16z" fill="#DFFE7F" />
  </svg>
);

export default AccordionMinusIcon;
