import {
  ReactElement,
  useId,
  useState,
  KeyboardEvent,
  useCallback,
  useEffect,
  HTMLAttributes,
} from 'react';
import { keyHandler } from '../../utils/accessibilityUtils';
import {
  Accordion as StyledAccordion,
  AccordionContent,
  AccordionHeader,
  AccordionContentWrapper,
} from './Accordion.styles';
import { AnimatePresence, motion } from 'framer-motion';
import AccordionPlusIcon from '../icons/AccordionPlusIcon';
import AccordionMinusIcon from '../icons/AccordionMinusIcon';
import styled from 'styled-components';
import CaretDownIcon from '../icons/CaretDownIcon';

export interface AccordionProps {
  variant?: 'primary' | 'secondary';
  header: string | ReactElement;
  content: string | ReactElement;
  className?: string;
  defaultOpen?: boolean;
  icon?: ReactElement;
  iconShouldRotate?: boolean;
  onClick?: () => void;
  attributes?: HTMLAttributes<HTMLElement>;
}

const CircleButton = styled.div`
  height: 34px;
  width: 34px;
  color: ${(props) => props.theme.colors.wrenoYellowPrimary};
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.black};
  margin-left: 10px;
`;

const Accordion = ({
  header,
  content,
  className,
  variant,
  defaultOpen,
  iconShouldRotate,
  onClick,
  attributes,
  icon,
}: AccordionProps) => {
  const [isOpen, setOpen] = useState(defaultOpen || false);
  const id = useId();

  useEffect(() => {
    setOpen(defaultOpen || false);
  }, [defaultOpen]);

  const onAccordionClick = useCallback(() => {
    setOpen(!isOpen);
    onClick && onClick();
  }, [setOpen, isOpen, onClick]);

  const _onKeyDown = (e: KeyboardEvent) => {
    keyHandler({
      keyEvent: e,
      eventHandler: onAccordionClick,
    });
  };

  return (
    <StyledAccordion className={className} {...attributes} $variant={variant}>
      <AccordionHeader
        role="button"
        $variant={variant}
        aria-expanded={isOpen}
        className={`accordion_header`}
        onClick={onAccordionClick}
        id={`accordion-header-${id}`}
        aria-controls={`accordion-content-${id}`}
        tabIndex={0}
        onKeyDown={_onKeyDown}
        data-testid="accordion-header"
        $shouldRotate={iconShouldRotate}
        $isOpen={isOpen}
      >
        {header}
        {variant === 'secondary' ? (
          <SecondaryAccordionIcon isOpen={isOpen} icon={icon} />
        ) : (
          <PrimaryAccordionIcon isOpen={isOpen} />
        )}
      </AccordionHeader>
      <AnimatePresence mode="wait">
        {isOpen ? (
          <AccordionContent
            role="region"
            transition={{ duration: 0.4 }}
            exit={{
              height: 0,
              opacity: 0,
            }}
            initial={{
              height: 0,
              opacity: 0,
            }}
            animate={{
              height: 'auto',
              opacity: 1,
            }}
            id={`accordion-content-${id}`}
            aria-labelledby={`accordion-header-${id}`}
            className={`accordion_content`}
            data-testid="accordion-content"
          >
            <AccordionContentWrapper $variant={variant}>
              {content}
            </AccordionContentWrapper>
          </AccordionContent>
        ) : null}
      </AnimatePresence>
    </StyledAccordion>
  );
};

function PrimaryAccordionIcon({ isOpen }: { isOpen: boolean }) {
  return (
    <CircleButton>
      <motion.div
        style={{
          transformOrigin: 'center',
          height: '34px',
        }}
        animate={
          isOpen
            ? {
                transform: 'rotate(180deg)',
              }
            : { transform: 'rotate(0deg)' }
        }
      >
        {isOpen ? <AccordionMinusIcon /> : <AccordionPlusIcon />}
      </motion.div>
    </CircleButton>
  );
}

function SecondaryAccordionIcon({
  icon,
  isOpen,
}: {
  icon?: ReactElement;
  isOpen: boolean;
}) {
  return (
    <motion.div
      style={{
        transformOrigin: 'center',
      }}
      animate={
        isOpen
          ? {
              transform: 'rotate(180deg)',
            }
          : { transform: 'rotate(0deg)' }
      }
    >
      {icon || <CaretDownIcon />}
    </motion.div>
  );
}

export default Accordion;
