import canUseDOM from 'common/utils/canUseDOM';

// Global Events that allow communicating with app if available
export const wrenoAppEvents = {
  onCloseWebview: () => {
    _postMessage('closeModal');
  },
  onAuthToken: (token: string) => {
    _postMessage('setAuthToken', token);
  },
};

// This is a function because isEnabled can change depending on when called
export const isWrenoApp = () =>
  canUseDOM && window.isNativeApp ? true : false;

// Internal function for creating a standardized bridge between RN and Web
const _postMessage = (eventName: string, payload?: unknown) => {
  if (isWrenoApp()) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ eventName, payload }),
    );
  }
};
