import canUseDOM from 'common/utils/canUseDOM';

export function getItem(key: string): string | null {
  if (!canUseDOM) {
    return null;
  }
  try {
    const rawData = localStorage.getItem(key);
    return rawData || null;
  } catch {
    return null;
  }
}

export function getItemWithExpiry<T>(key: string): T | null {
  if (!canUseDOM) {
    return null;
  }
  const itemStr = getItem(key);
  if (!itemStr) {
    return null;
  }
  try {
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage and return null
      removeItem(key);
      return null;
    }
    try {
      return JSON.parse(item.value);
    } catch {
      return item.value;
    }
  } catch (err) {
    return null;
  }
}

export const setItem = (key: string, value: unknown): boolean => {
  if (!canUseDOM) return false;
  try {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, `${value}`);
    }
    return true;
  } catch {
    return false;
  }
};

export function setItemWithExpiry(
  key: string,
  value: unknown,
  timeToLive?: number,
): boolean {
  const now = new Date();
  const item = {
    value: value,
    ...(timeToLive && {
      expiry: now.getTime() + timeToLive,
    }),
  };
  return setItem(key, item);
}

export const removeItem = (key: string): boolean => {
  if (!canUseDOM) {
    return false;
  }
  try {
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};
