import styled from 'styled-components';
import Typography from '../Typography';
import Link from 'next/link';

export const LogoWrapper = styled.div`
  & img {
    pointer: cursor;
  }
`;

export const Title = styled(Typography)`
  margin-right: 33px;
  color: ${({ theme }) => theme.colors.gray400};
`;

export const TitleLink = styled(Link)`
  text-decoration: none;
`;

export const FooterInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media ${({ theme }) => theme.breakpoints.large} {
    flex-direction: row;
    align-items: center;
  }
`;

export const FooterLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 53.4px;
  margin-bottom: 12px;
`;

export const FooterLargeLinkContainer = styled.div`
  display: flex;
  flex: 1;
  padding-left: 87px;
  align-items: center;
  flex-direction: row;
  margin: 0 auto;
`;

export const FooterOuterContainer = styled.div`
  padding: 23px 50px;
  background: ${({ theme }) => theme.colors.black};
  width: 100%;
  box-sizing: border-box;
  height: ${({ theme }) => theme.variables.footerHeightMobile};

  @media ${({ theme }) => theme.breakpoints.large} {
    padding: 23px 50px;
    height: ${({ theme }) => theme.variables.footerHeight};
  }
`;
