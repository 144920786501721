import { useEffect, useState } from 'react';
import canUseDOM from '../utils/canUseDom';
import { useTheme } from 'styled-components';

const useResponsive = () => {
  const [isRendering, setIsRendering] = useState(true);
  const [isSmall, setIsSmall] = useState(false);
  const [isMedium, setIsMedium] = useState(false);
  const [isLarge, setIsLarge] = useState(true);
  const [isXLarge, setIsXLarge] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (!canUseDOM) return;
    const handleResize = () => {
      setIsSmall(window?.innerWidth < theme.breakpointsRaw.medium);
      setIsMedium(window?.innerWidth >= theme.breakpointsRaw.medium);
      setIsLarge(window?.innerWidth >= theme.breakpointsRaw.large);
      setIsXLarge(window?.innerWidth >= theme.breakpointsRaw.xlarge);
      setIsRendering(false);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [theme.breakpointsRaw]);

  return {
    isSmall,
    isMedium,
    isLarge,
    isXLarge,
    isRendering,
  };
};

export default useResponsive;
