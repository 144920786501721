import { CSSProperties, forwardRef } from 'react';
import {
  Container,
  Message,
  MessageContentWrapper,
  Title,
} from './MessageAlert.styles';

export interface Props {
  title?: string | React.ReactNode;
  message: string | React.ReactNode;
  className?: string;
  inline?: boolean;
  'data-testid'?: string;
  style?: CSSProperties;
  fill?: boolean;
  icon?: React.ReactNode;
  hideBorder?: boolean;
}

const MessageAlert = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      message,
      className,
      style,
      'data-testid': testId,
      icon,
      hideBorder,
    }: Props,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): React.ReactElement => {
    return (
      <Container
        data-testid={testId}
        ref={ref}
        className={className}
        style={style}
        $hideBorder={hideBorder}
      >
        {icon && icon}
        <MessageContentWrapper>
          {title && <Title>{title}</Title>}
          {message && <Message variant="bodyLarge">{message}</Message>}
        </MessageContentWrapper>
      </Container>
    );
  },
);

MessageAlert.displayName = 'MessageAlert';

export default MessageAlert;
