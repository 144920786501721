import type { SVGAttributes } from 'react';

const CloseIcon = (props: SVGAttributes<SVGElement>) => {
  const { fill = 'currentColor' } = props;
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.293 12.292a1 1 0 1 0 1.414 1.414L7 8.414l5.293 5.293a1 1 0 0 0 1.414-1.415L8.414 7l5.292-5.293A1 1 0 1 0 12.292.293L7 5.586 1.707.294A1 1 0 0 0 .293 1.708L5.585 7 .293 12.292Z"
        fill={fill}
      />
    </svg>
  );
};

export default CloseIcon;
