import { KeyboardEvent } from 'react';

export const keyHandler = ({
  keyEvent,
  customQualifier = true,
  eventHandler,
}: {
  keyEvent: KeyboardEvent;
  customQualifier?: boolean;
  eventHandler: () => void;
}) => {
  if (document.activeElement !== keyEvent.currentTarget) return;
  if ((keyEvent.key === 'Enter' || keyEvent.key === ' ') && customQualifier) {
    keyEvent.preventDefault();
    keyEvent.stopPropagation();
    if (eventHandler) eventHandler();
    return false;
  }
};
