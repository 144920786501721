import { ReactNode } from 'react';
import theme from '../../../themes/theme';
import PageHeader from '../../AppShell/PageHeader/PageHeader';
import Button from '../../Button';
import ButtonRow from '../../ButtonRow/ButtonRow';
import MessageAlert from '../../MessageAlert';
import CircleExclamationFilledIcon from '../../icons/CircleExclamationFilledIcon';
import Modal from '../Modal';
import { ContentContainer } from './ConfirmModal.styles';

type ConfirmModalProps = {
  title: string;
  isOpen: boolean;
  message: ReactNode | string;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmModal = ({
  title,
  message,
  isOpen,
  onClose,
  onConfirm,
}: ConfirmModalProps) => (
  <Modal open={isOpen} onClose={onClose}>
    <ContentContainer>
      <PageHeader title={title} />
      <MessageAlert
        icon={
          <CircleExclamationFilledIcon
            width={32}
            height={32}
            color={theme.colors.passOk}
          />
        }
        message={message}
        hideBorder
      />

      <ButtonRow
        leftContent={
          <Button variant="secondary" color="alert" onClick={onClose}>
            Cancel
          </Button>
        }
        rightContent={
          <Button variant="primary" onClick={onConfirm}>
            Understood
          </Button>
        }
      />
    </ContentContainer>
  </Modal>
);

export default ConfirmModal;
