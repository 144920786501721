import CaretDownIcon from '../../../icons/CaretDownIcon';
import { useRouter } from 'next/router';
import { ContextMenuButton, StyledContextMenu } from './LinkWithMenu.styles';
import { LinkSubMenu } from '../types';
import { useTheme } from 'styled-components';
import { useDarkMode } from '../../../../hooks/useDarkMode';

type LinkWithMenuProps = {
  id: string;
  title: string;
  subMenu: LinkSubMenu;
  activeMenu?: string;
  setActiveMenu: (menuId?: string) => void;
};

const LinkWithMenu = ({
  id,
  title,
  subMenu,
  activeMenu,
  setActiveMenu,
}: LinkWithMenuProps) => {
  const router = useRouter();
  const isMenuItemActive = (href: string) => router.asPath === href;
  const theme = useTheme();
  const { darkMode } = useDarkMode();

  const renderLinkMenu = () => (
    <>
      <StyledContextMenu
        items={subMenu.map((menuItem) => ({
          link: menuItem.url,
          label: menuItem.title,
          color: isMenuItemActive(menuItem.url)
            ? theme.colors.machineGreen600
            : theme.colors.black,
        }))}
        align="start"
        onOpenChange={(open) => {
          if (!open && activeMenu === id) {
            setActiveMenu(undefined);
          } else if (open && activeMenu !== id) {
            setActiveMenu(id);
          }
        }}
        className="primary-nav-context"
        onCloseAutoFocus={(e) => e.preventDefault()}
        open={activeMenu === id}
      >
        <ContextMenuButton
          $isActive={activeMenu === id}
          $darkMode={darkMode}
          onClick={() => setActiveMenu(id)}
        >
          {title}
          <CaretDownIcon height={6.5} width={11} />
        </ContextMenuButton>
      </StyledContextMenu>
    </>
  );

  return renderLinkMenu();
};

export default LinkWithMenu;
