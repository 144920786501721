import styled from 'styled-components';
import { Variant } from './ButtonRow';

export const Row = styled.div<{ $compact?: boolean; $variant?: Variant }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: ${({ $compact }) => ($compact ? 0 : 32)}px;
  width: 100%;
  box-sizing: border-box;

  ${({ $variant, theme }) =>
    $variant === 'stacked' &&
    `
  flex-direction: column-reverse;
  justify-content: unset;

  @media ${theme.breakpoints.medium} {
    flex-direction: row;
    justify-content: space-between;

  }
  `}
`;

export const Column = styled.div<{ $variant?: Variant }>`
  display: flex;
  gap: 8px;

  ${({ $variant, theme }) =>
    $variant === 'stacked' &&
    `
    flex-direction: column-reverse;
    width: 70%;

    @media ${theme.breakpoints.medium} {
      flex-direction: row;
      width: unset;
    }
    `}
`;
