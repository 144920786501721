import { SVGProps } from 'react';

const AccordionPlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={34}
    height={34}
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={17} cy={17} r={17} fill="#131313" />
    <path
      transform="rotate(90 25 16.5)"
      fill="#DFFE7F"
      d="M25 16.5H27V32.5H25z"
    />
    <path
      transform="rotate(-180 18 25.5)"
      fill="#DFFE7F"
      d="M18 25.5H20V41.5H18z"
    />
  </svg>
);

export default AccordionPlusIcon;
