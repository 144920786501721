import type { SVGAttributes } from 'react';

const MenuIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width={28}
    height={18}
    viewBox="0 0 28 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={28} height={2} rx={1} fill="currentColor" />
    <rect y={8} width={28} height={2} rx={1} fill="currentColor" />
    <rect y={16} width={28} height={2} rx={1} fill="currentColor" />
  </svg>
);

export default MenuIcon;
