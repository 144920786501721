import styled from 'styled-components';
import ContextMenu from '../../../ContextMenu';
import { CTAStandard } from '../../../Typography/Typography.styles';

export const ContextMenuButton = styled.div<{
  $isActive?: boolean;
  $darkMode: boolean;
}>`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${CTAStandard};
  margin-right: 0px;
  padding: 10px 12px ${({ $isActive }) => ($isActive ? '5px' : '10px')};
  border-radius: 5px;
  color: ${({ $darkMode, theme }) =>
    $darkMode ? theme.colors.white : theme.colors.black};

  &:hover {
    background: ${({ theme, $darkMode }) =>
      $darkMode ? theme.colors.gray900 : theme.colors.gray50};
  }

  & svg {
    transition: transform 100ms;
    margin-left: 8px;

    ${({ $isActive }) =>
      $isActive &&
      `
  transform: rotate(180deg);
  `};
  }

  @media ${({ theme }) => theme.breakpoints.large} {
    margin-right: 22px;
  }
`;

export const StyledContextMenu = styled(ContextMenu)<{ $isActive?: boolean }>`
  &.primary-nav-context {
    border-radius: 5px;
    padding: 10px;

    & .context_menu_item {
      color: ${({ theme }) => theme.colors.black};
      font-family: ${({ theme }) => theme.fontFamily.main};
      justify-content: flex-start;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      border-radius: 5px;
      &:hover {
        background: ${({ theme }) => theme.colors.gray50};
      }
      padding: 10px 16px;

      ${({ $isActive, theme }) =>
        $isActive &&
        `
      color: ${theme.colors.machineGreen600};
      `};
    }
  }
`;
