import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(19, 32, 19, 0.25);
  backdrop-filter: blur(2px);
  z-index: 10;
  overflow: auto;
`;

export const ModalContent = styled.div`
  outline: none;
  width: 95%;
  margin: 10vh auto;
  padding: 2rem;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 6px 50px rgba(19, 32, 19, 0.02);
  border-radius: 16px;
  position: relative;
  transition:
    transform 200ms,
    opacity 200ms;
  opacity: 0;
  box-sizing: border-box;
  transform: scale(0);
  &.ReactModal__Content--after-open {
    opacity: 1;
    transform: scale(1);
  }
  &.ReactModal__Content--before-close {
    opacity: 0;
    transform: scale(0);
  }
  @media (${(props) => props.theme.breakpoints.large}) {
    width: 50vw;
  }
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  z-index: 1;
  height: 24px;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  color: ${({ theme }) => theme.colors.black};
`;
