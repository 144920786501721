import {
  AnnounceCloseButton,
  AnnouncementBarActionLabel,
  AnnouncementBarLabel,
  AnnouncementBarStyled,
  AnnouncementTextContainer,
} from './AnnouncementBar.styles';
import CloseIcon from '../icons/CloseIcon';
import theme from '../../themes/theme';

export type Announcement = {
  variant?: 'primary' | 'secondary';
  text: string;
  link?: {
    label: string;
    url: string;
  };
};

export type AnnouncementBarProps = {
  label: string;
  actionLabel?: string;
  variant?: 'primary' | 'secondary';
  href?: string;
  onClose?: () => void;
};

export default function AnnouncementBar(props: AnnouncementBarProps) {
  const {
    label = '',
    actionLabel = '',
    variant = 'primary',
    href = '',
    onClose,
  } = props;
  return (
    <AnnouncementBarStyled $variant={variant}>
      <AnnouncementTextContainer>
        <AnnouncementBarLabel>{label}</AnnouncementBarLabel>
        <AnnouncementBarActionLabel
          href={href}
          target={'_blank'}
          $variant={variant}
        >
          {actionLabel}
        </AnnouncementBarActionLabel>
      </AnnouncementTextContainer>
      <AnnounceCloseButton aria-label="Dismiss Banner" onClick={onClose}>
        <CloseIcon
          fill={
            variant === 'primary' ? undefined : theme.colors.wrenoYellowPrimary
          }
        />
      </AnnounceCloseButton>
    </AnnouncementBarStyled>
  );
}
