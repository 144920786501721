import type { SVGAttributes } from 'react';

const CaretDownIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9983 13.15C11.7983 13.15 11.615 13.1167 11.4483 13.05C11.2817 12.9833 11.115 12.8667 10.9483 12.7L1.04835 2.79999C0.781679 2.53332 0.656679 2.17499 0.673346 1.72499C0.690012 1.27499 0.831679 0.916659 1.09835 0.649992C1.43168 0.316658 1.79001 0.174993 2.17335 0.224993C2.55668 0.274993 2.89835 0.433326 3.19835 0.699993L11.9983 9.49999L20.7983 0.699993C21.065 0.433326 21.4233 0.283326 21.8733 0.249992C22.3233 0.216659 22.6817 0.36666 22.9483 0.699993C23.2817 0.96666 23.4233 1.31666 23.3733 1.74999C23.3233 2.18333 23.165 2.54999 22.8983 2.84999L13.0483 12.7C12.8817 12.8667 12.715 12.9833 12.5483 13.05C12.3817 13.1167 12.1983 13.15 11.9983 13.15Z"
      fill="currentColor"
    />
  </svg>
);

export default CaretDownIcon;
