import { PropsWithChildren, ReactElement } from 'react';
import { ModalProps } from '../Modal';
import { Header, Modal, Body } from './ModalWithHeader.styles';

interface Props extends ModalProps {
  headerContent: ReactElement;
}

const ColoredHeaderModal = ({
  children,
  headerContent,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <Modal {...rest}>
      <Header className="Wreno_Modal--header">{headerContent}</Header>
      <Body className="Wreno_Modal--body">{children}</Body>
    </Modal>
  );
};

export default ColoredHeaderModal;
