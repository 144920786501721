import styled, { keyframes } from 'styled-components';
import { Root, Trigger, Content } from '@radix-ui/react-popover';
import Button from '../Button';

const fadeIn = keyframes`
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Divider = styled.hr`
  margin: 16px 0;
  border-width: 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.gray800};
  border-bottom-width: thin;
  opacity: 0.18;
`;

export const MenuWrapper = styled(Root)``;

export const MenuButtonWrapper = styled(Trigger)`
  background: transparent;
  border: none;
  width: auto;
  height: auto;
  display: inline;

  :not(:focus-visible) {
    outline: none;
  }
`;

export const MenuButtonInner = styled.div`
  background: #ffffff;
  border: none;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const MenuListItem = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const MenuListWrapper = styled(Content)`
  background: ${(props) => props.theme.colors.gray50};
  margin-top: 6px;
  border-radius: 4px;
  filter: drop-shadow(0px 15px 30px rgba(19, 32, 19, 0.15));
  border: none;
  padding-top: 16px;
  padding-bottom: 16px;
  z-index: 10;
  outline: none;
  animation-name: ${fadeIn};
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-timing-function: ease;
`;

export const MenuItemWrapper = styled(Button)<{ $textColor: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  background: none;
  color: ${(props) => props.$textColor || props.theme.colors.gray800};
  font-size: 15px;

  svg {
    margin-right: 5px;
    width: 18px;
    height: 18px;
  }
  &:hover {
    color: ${(props) => props.$textColor || props.theme.colors.gray500};
  }
`;

export const Border = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.gray400};
  width: 90%;
  margin: 10px auto;
`;

export const ContextLabel = styled.span`
  padding: 8px 16px;
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
  white-space: normal;
  word-break: normal;
  color: ${({ theme }) => theme.colors.machineGreen600};
`;

export const ContextSubLabel = styled.span`
  padding: 8px 16px;
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
  white-space: normal;
  word-break: normal;
  color: ${(props) => props.color || props.theme.colors.gray800};
  font-size: 14px;
`;
