import LinkWithMenu from './LinkWithMenu';
import LinkSimple from './LinkSimple';
import { Link } from '../types';

type PrimaryNavLinkProps = {
  linkItem: Link;
  activeMenu?: string;
  onClick: (id: string | undefined) => void;
};

const PrimaryNavLink = ({
  linkItem,
  activeMenu,
  onClick,
}: PrimaryNavLinkProps) => {
  const href = linkItem?.url;
  const subMenu =
    linkItem?.sublinks && linkItem.sublinks.length ? linkItem.sublinks : null;

  if (subMenu) {
    return (
      <LinkWithMenu
        id={linkItem.title}
        title={linkItem.title}
        subMenu={subMenu}
        activeMenu={activeMenu}
        setActiveMenu={onClick}
      />
    );
  }
  return <LinkSimple {...linkItem} href={href || ''} />;
};

export default PrimaryNavLink;
