import styled from 'styled-components';
import BaseModal from '../Modal';
import Button from '../../Button';

export const DrawerModal = styled(BaseModal)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  border-radius: 0;
  opacity: 0;
  transform: translateX(100%);
  box-sizing: border-box;
  transition: all 200ms ease-in-out;
  width: min(80%, 270px);
  &.ReactModal__Content--after-open {
    opacity: 1;
    transform: translateX(0);
  }

  &.ReactModal__Content--before-close {
    opacity: 0;
    transform: translateX(100%);
  }
`;

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  width: 100%;
`;

export const DrawerBody = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
  padding-bottom: 4rem;
`;
export const DrawerScrollContainer = styled.div`
  margin: 2rem 2rem 4rem;
`;

export const DrawerActions = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-top: 1px solid #fff;

  padding: 1rem 30px;
  gap: 8px;

  button {
    flex: 1;
  }
`;

export const DrawerTitleRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const DrawerCloseButton = styled.button`
  width: 24px;
  z-index: 1;
  height: 24px;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  color: ${({ theme }) => theme.colors.black};
  & > svg {
    width: 10px;
    height: 10px;
  }
`;

export const DrawerActionButton = styled(Button)`
  white-space: nowrap;
  font-size: 14px;
`;
