import { ReactNode } from 'react';
import { Row, Column } from './ButtonRow.styles';

export type Variant = 'inline' | 'stacked';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  compact?: boolean;
  mobileVariant?: Variant;
}

export default function ButtonRow({
  leftContent,
  rightContent,
  compact,
  mobileVariant = 'inline',
  ...rest
}: Props) {
  return (
    <Row $compact={compact} $variant={mobileVariant} {...rest}>
      {leftContent ? (
        <Column $variant={mobileVariant}>{leftContent}</Column>
      ) : (
        <Column />
      )}
      {rightContent ? (
        <Column $variant={mobileVariant}> {rightContent}</Column>
      ) : (
        <Column />
      )}
    </Row>
  );
}
