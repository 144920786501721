import type { PropsWithChildren } from 'react';
import BaseModal, { Props } from 'react-modal';

import { ModalCloseButton, ModalContent, ModalOverlay } from './Modal.styles';

import CloseIcon from '../icons/CloseIcon';

export interface ModalProps extends Partial<Props> {
  /**
   * Modal state, open/close
   */
  open: boolean;
  /**
   * Force hide close button
   */
  hideCloseBtn?: boolean;
  /**
   * Modal content class name
   */
  className?: string;
  /**
   * Modal close callback
   */
  onClose?: () => void;
}
const IS_TEST = process.env.NODE_ENV === 'test';
if (!IS_TEST) {
  BaseModal.setAppElement('#__next');
}

const Modal = ({
  open,
  hideCloseBtn,
  className,
  children,
  onClose,
  ...props
}: PropsWithChildren<
  Omit<ModalProps, 'isOpen' | 'onRequestClose' | 'overlayClassName'>
>) => (
  <BaseModal
    {...props}
    className="_"
    overlayClassName="_"
    isOpen={open}
    ariaHideApp={!IS_TEST}
    closeTimeoutMS={200}
    onRequestClose={(e) => {
      e.stopPropagation();
      if (onClose) {
        onClose();
      }
    }}
    overlayElement={(props, contentElement) => (
      <ModalOverlay
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        {...props}
      >
        {contentElement}
      </ModalOverlay>
    )}
    contentElement={(props, children) => (
      <ModalContent
        {...props}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`${className} ${props.className || ''}`}
      >
        {children}
      </ModalContent>
    )}
  >
    {!hideCloseBtn && (
      <ModalCloseButton
        onClick={onClose}
        data-testid="modal-close-button"
        id="close-button"
      >
        <CloseIcon />
      </ModalCloseButton>
    )}
    {children}
  </BaseModal>
);

export default Modal;
