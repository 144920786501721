import { useRouter } from 'next/router';
import {
  AccordionHeading,
  ContentWrapper,
  Link,
  StyledAccordion,
} from './LinkWithAccordionMenu.styles';
import CaretDownIcon from '../../../icons/CaretDownIcon';
import { LinkSubMenu } from '../types';

type LinkWithAccordionMenuProps = {
  id: string;
  activeMenu?: string;
  title: string;
  subMenu: LinkSubMenu;
  setActiveMenu: (id: string) => void;
};

const LinkWithAccordionMenu = ({
  title,
  subMenu,
  setActiveMenu,
  id,
  activeMenu,
}: LinkWithAccordionMenuProps) => {
  const router = useRouter();
  const isMenuItemActive = (href: string) => router.asPath === href;

  const renderLinkMenu = () => (
    <StyledAccordion
      $isOpen={activeMenu === id}
      onClick={() => {
        setActiveMenu(id);
      }}
      header={<AccordionHeading>{title}</AccordionHeading>}
      content={
        <ContentWrapper>
          {subMenu.map((item, index) => (
            <Link
              key={index}
              href={item.url}
              $isActive={isMenuItemActive(item.url)}
            >
              {item.title}
            </Link>
          ))}
        </ContentWrapper>
      }
      icon={<CaretDownIcon />}
      iconShouldRotate
    />
  );

  return renderLinkMenu();
};

export default LinkWithAccordionMenu;
