import type { SVGAttributes } from 'react';

const CaretLeftIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width={8}
    height={14}
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.665.342a1.184 1.184 0 0 1 0 1.65L2.76 7l4.906 5.008a1.184 1.184 0 0 1 0 1.65 1.126 1.126 0 0 1-1.616 0L.335 7.825a1.184 1.184 0 0 1 0-1.65L6.049.342a1.126 1.126 0 0 1 1.616 0Z"
      fill="currentColor"
    />
  </svg>
);

export default CaretLeftIcon;
