import Typography from '../../Typography';
import CaretLeftIcon from '../../icons/CaretLeftIcon';
import { BackButton, Container, Content } from './PageHeader.styles';

interface Props {
  backHref?: string;
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  profilePicture?: React.ReactNode;
  immersive?: boolean;
  className?: string;
}

export default function PageHeader({
  title,
  subtitle,
  profilePicture,
  backHref,
  immersive,
  className,
}: Props) {
  return (
    <Container $immersive={immersive} className={className}>
      {backHref && (
        <BackButton variant="tertiary" href={backHref} icon={<CaretLeftIcon />}>
          Back
        </BackButton>
      )}
      <Content>
        {profilePicture}
        <div>
          <Typography variant="heading3">{title}</Typography>
          <Typography variant="bodySmall">{subtitle}</Typography>
        </div>
      </Content>
    </Container>
  );
}
